/* global requestAnimationFrame window */
import React, { PureComponent, } from 'react';
// import Navigation from './components/Navigation/Navigation';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { connect, } from 'react-redux';
import { Route, Switch, withRouter, } from 'react-router-dom';
import { disableBodyScroll, enableBodyScroll, } from 'body-scroll-lock';
import StyleguideContainer from './pages/Styleguide/StyleguideContainer';
import Photos from './pages/Photos/Photos';
import Home from './pages/Home/Home';
import Header from './components/Header/Header';
import './App.scss';

/* <svg viewBox="0 0 42 42" preserveAspectRatio="none">
    <path d="M1 21 L21 1 L41 21 L21 41 Z" fill="#47525d" stroke="#47525d" strokeWidth="2" />
    <path d="M14 18 L28 18" fill="none" stroke="#cdeadc" />
    <path d="M12 22 L28 22" fill="none" stroke="#cdeadc" />
    <path d="M18 26 L26 26" fill="none" stroke="#cdeadc" />
</svg> */

/* eslint-disable */
function inOutQuad(n) {
    n *= 2;
    if (n < 1) return 0.5 * n * n;
    return - 0.5 * (--n * (n - 2) - 1);
};

function easeInOutCubic(t) { return t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1 };
/* eslint-enable */

class App extends PureComponent {


    state = {
        style: 'translate3d(-16px, 16px, 0) rotate(45deg) scale(1, 1)',
    };

    componentDidMount() {
        this.backdrop.addEventListener('transitionend', this.onNavAnimEnd, false);
    }

    componentWillUnmount() {
        this.backdrop.removeEventListener('transitionend', this.onNavAnimEnd, false);
    }

    onNavAnimEnd = (ev) => {
        console.log('ev', ev);
    }

    componentDidUpdate(prevProps) {

        const { route, mobileMenuOpen, } = this.props;

        if (prevProps.route !== route) {
            console.log('route change', route);
        }

        if (mobileMenuOpen && !prevProps.mobileMenuOpen) {
            disableBodyScroll(this.backdrop);
            if (this.isAnimating) { return; }
            this.isAnimating = true;
            this.animateMenuOpen();
        } else if (prevProps.mobileMenuOpen && !mobileMenuOpen) {
            if (this.isAnimating) { return; }
            this.isAnimating = true;
            this.animateMenuClose();
            enableBodyScroll(this.backdrop);
        }
    }

    animateMenuOpen = () => {
        const h = window.innerHeight + 60;
        const w = window.innerWidth + 10;
        this.menuOpenScaleX = w / 30;
        this.menuOpenScaleY = h / 30;
        this.animateMenu({
            startAngle: 45,
            endAngle: 0,
            startScaleX: 1,
            startScaleY: 1,
            endScaleX: this.menuOpenScaleX,
            endScaleY: this.menuOpenScaleY,
            startTranslateX: 16,
            startTranslateY: 16,
            endTranslateX: w / 2 - 16,
            endTranslateY: h / 2 - 16,
            open: true,
        });
    }

    animateMenuClose = () => {
        const h = window.innerHeight + 60;
        const w = window.innerWidth + 10;
        this.animateMenu({
            startAngle: 0,
            endAngle: 45,
            startScaleX: this.menuOpenScaleX,
            startScaleY: this.menuOpenScaleY,
            endScaleX: 1,
            endScaleY: 1,
            startTranslateX: w / 2 - 16,
            startTranslateY: h / 2 - 16,
            endTranslateX: 16,
            endTranslateY: 16,
            open: false,
        });
    }

    animateMenu = ({
        startAngle,
        endAngle,
        startScaleX,
        endScaleX,
        startScaleY,
        endScaleY,
        startTranslateX,
        endTranslateX,
        startTranslateY,
        endTranslateY,
        open,
    }) => {
        const scaleDuration = 400;
        const rotateDuration = 200;
        const self = this;
        let stop = false;
        let start;
        let endScale;
        let startRotate;
        let endRotate;

        function draw(now) {
            if (stop) {
                self.setState({ style: `translate3d(-${endTranslateX}px, ${endTranslateY}px, 0) rotate(${endAngle}deg) scale(${endScaleX}, ${endScaleY})`, });
                self.isAnimating = false;
                return;
            }

            if (now >= endScale) { stop = true; }
            const rotateT = inOutQuad((now - start) / rotateDuration);
            const scaleT = easeInOutCubic((now - start) / scaleDuration);
            let x;
            if (now >= endRotate) {
                x = endAngle;
            } else if (now < startRotate) {
                x = startAngle;
            } else {
                x = startAngle + (endAngle - startAngle) * rotateT;
            }
            const scaleX = startScaleX + (endScaleX - startScaleX) * scaleT;
            const scaleY = startScaleY + (endScaleY - startScaleY) * scaleT;
            const translateX = startTranslateX + (endTranslateX - startTranslateX) * scaleT;
            const translateY = startTranslateY + (endTranslateY - startTranslateY) * scaleT;
            self.setState({ style: `translate3d(-${translateX}px, ${translateY}px, 0) rotate(${x}deg) scale(${scaleX}, ${scaleY})`, });
            requestAnimationFrame(draw);
        }

        function startAnim(timeStamp) {
            start = timeStamp;
            endScale = start + scaleDuration;
            if (open) {
                startRotate = start;
                endRotate = start + rotateDuration;
            } else {
                startRotate = endScale - rotateDuration;
                endRotate = endScale;
            }
            draw(timeStamp);
        }

        requestAnimationFrame(startAnim);
    }

    setBackdropRef = (element) => { this.backdrop = element; };

    toggleMobileMenu = () => {
        this.props.toggleMenu(!this.props.mobileMenuOpen);
    }

    render() {

        const { mobileMenuOpen, closeMenu, } = this.props;
        const { style, } = this.state;

        return (
            <div className={cx('app', {
                'mobile-menu-open': mobileMenuOpen,
            })}>

                <div id="curtain" onClick={closeMenu} role="presentation" onKeyDown={closeMenu} />

                <nav className="backdrop" ref={this.setBackdropRef}>
                    <div className="backdrop-bg" style={{ transform: style, }} />
                    <button onClick={this.toggleMobileMenu} className="menu-link">
                        <svg viewBox="0 0 42 42" preserveAspectRatio="none">
                            <path d="M14 18 L28 18" fill="none" stroke="#cdeadc" />
                            <path d="M12 22 L28 22" fill="none" stroke="#cdeadc" />
                            <path d="M18 26 L26 26" fill="none" stroke="#cdeadc" />
                        </svg>
                    </button>
                </nav>

                <aside>
                    <nav />
                </aside>

                <div className="page-wrapper">

                    <Header />

                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route path="/styleguide" component={StyleguideContainer} />
                        <Route path="/photos" component={Photos} />
                    </Switch>

                    <footer>
                        <svg viewBox="0 0 400 200" preserveAspectRatio="none">
                            <defs>
                                <linearGradient
                                    id="bg-gradient"
                                    x1="0%"
                                    y1="0%"
                                    x2="0"
                                    y2="100%"
                                    spreadMethod="pad"
                                >
                                    <stop
                                        offset="0%"
                                        stopColor="#adf5d2"
                                        stopOpacity="0.5"
                                    />
                                    <stop
                                        offset="100%"
                                        stopColor="#cdeadc"
                                        stopOpacity="0.8"
                                    />
                                </linearGradient>
                            </defs>
                            <path d="M0 60 Q100 0 200 50 T400 50 L400 200 L0 200 Z" fill="url(#bg-gradient)" />
                            <path d="M0 40 Q80 20 160 60 T320 60 T400 30 L400 200 L0 200 Z" fill="url(#bg-gradient)" />
                        </svg>
                    </footer>
                </div>

            </div>
        );
    }
}

App.propTypes = {
    mobileMenuOpen: PropTypes.bool.isRequired,
    closeMenu: PropTypes.func.isRequired,
    toggleMenu: PropTypes.func.isRequired,
    route: PropTypes.string,
};

App.defaultProps = {
    route: '',
};

const mapStateToProps = state => ({
    mobileMenuOpen: state.navigation.mobileMenuOpen,
});

const mapDispatchToProps = dispatch => ({
    closeMenu: () => dispatch({ type: 'NAVIGATION_UPDATE_MENU_MOBILE', mobileMenuOpen: false, }),
    toggleMenu: mobileMenuOpen => dispatch({ type: 'NAVIGATION_UPDATE_MENU_MOBILE', mobileMenuOpen, }),
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
