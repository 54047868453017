import React from 'react';
import PropTypes from 'prop-types';
import statuses from '../../statuses';

import './TextStatuses.scss';

const TextStatuses = ({ statusPlaceholder, queue, }) => {

    const items = queue.map(item => {
        if (item.status === statuses.DEFAULT) { return null; }
        if (typeof item.message === 'boolean') { return null; }
        const key = `interaction-status-${item.status}`;
        return (
            <div key={key} className={key}>{item.message}</div>
        )
    });

    return (
        <div className="input-statuses">
            <div className="focus-bar"/>
            <div className="success-bar"/>
            <div className="error-bar"/>
            <div className="disabled-bar"/>
            <div className="interaction-status-placeholder">{statusPlaceholder}</div>
            {items}
        </div>
    );
};

TextStatuses.propTypes = {
    messages: PropTypes.arrayOf(PropTypes.shape({
        message: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.node,
            PropTypes.bool,
        ]),
        status: PropTypes.string,
    })),
};

TextStatuses.defaultProps = {
    message: '',
    statusPlaceholder: (<span>&nbsp;</span>),
};

export default TextStatuses;