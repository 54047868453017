import React from 'react';
import PropTypes from 'prop-types';
import Sign from '../Icons/Sign';

const EditSign = ({ setFocus, style, tabIndex, }) => (
    <button
        className="edit-sign"
        onClick={setFocus}
        tabIndex={tabIndex}
        style={style}
    >
        <Sign glyph="PENCIL"/>
    </button>
);

EditSign.propTypes = {
    setFocus: PropTypes.func.isRequired,
    style: PropTypes.shape({
        transform: PropTypes.string,
    }),
    tabIndex: PropTypes.number,
};

EditSign.defaultProps = {
    tabIndex: 0,
    style: {},
};

export default EditSign;