import React from 'react';
import Page from '../Page';
import Resume from './Resume';
import Quotes from './Quotes';
import './Home.scss';

const Home = () => (
    <Page title="Home | Garhammar" className="home">

        <div className="top">
            <section>
                <article>
                    <h1 className="top-header">
                        <span className="edge">
                            <i>on the</i> <strong>edge</strong>
                        </span>
                        <br className="up-to-desktodp" />
                        <span className="mediocrity">
                            <i>of</i> <span>mediocrity</span>
                        </span>

                    </h1>
                </article>
            </section>
        </div>

        <Resume />

        <Quotes />

        <section>
            <article>
                the end
            </article>
        </section>

    </Page>
);

export default Home;


/* <div className="bg-wrapper">

<svg viewBox="0 0 200 50">
    <defs>
        <linearGradient
            id="bg-gradient"
            x1="0%"
            y1="0%"
            x2="0"
            y2="100%"
            spreadMethod="pad"
        >
            <stop
                offset="0%"
                stopColor="#5cec9e"
                stopOpacity="0.5"
            />
            <stop
                offset="80%"
                stopColor="#5cec9e"
                stopOpacity="1"
            />
        </linearGradient>
        <radialGradient id="RadialGradient1">
            <stop offset="0%" stopColor="red"/>
            <stop offset="100%" stopColor="blue"/>
        </radialGradient>
        <radialGradient id="RadialGradient2" cx="0.25" cy="0.25" r="0.25">
            <stop offset="0%" stopColor="red"/>
            <stop offset="100%" stopColor="blue"/>
        </radialGradient>
    </defs>
    <path d="M0 0 L200 0 L200 30 L0 50 Z" fill="url(#bg-gradient)"/>
</svg>
</div> */


/* <section className="toned">
    <article>
        <p>
            <cite>
                Staffan has proven himself over and over again, there is no doubt he is absolute average. On all accounts.
                    </cite>
        </p>
        <p>
            <cite>
                This man, without a doubt, is a paragon of averageness.
                    </cite>
        </p>
        <p>
            <cite>
                Im just, wow, the sheer mediocrity of it all. Unheard of.
                    </cite>
        </p>
        <p>
            <cite>By the grace of God, he has taken ordinariness to the next level</cite>
        </p>
        <p>
            <cite>By the grace of God, In my whole career as a frontend dev Ive never witnessed such an averageness.</cite>
        </p>


    </article>
</section> */
