import React from 'react';
import PropTypes from 'prop-types';
import Sign from '../Icons/Sign';

const PendingSign = ({ style }) => (
    <div className="pending-sign" style={style}>
        <Sign glyph="PENDING"/>
    </div>
);

PendingSign.propTypes = {
    style: PropTypes.shape({
        transform: PropTypes.string,
    }),
};

PendingSign.defaultProps = {
    style: {},
};

export default PendingSign;