import React from 'react';
import PropTypes from 'prop-types';
import Sign from '../../ui/Icons/Sign';

const ResumeItem = ({ header, link, year, info, }) => (
    <div className="resume-item">
        <div className="resume-item-header">
            <div>{year}</div>
            <strong>{header}</strong>
        </div>
        <div className="resume-item-info">
            <div>
                <a href={link} target="_blank" rel="noopener noreferrer">
                    <Sign glyph="EXTERNAL_LINK" />
                </a>
            </div>
            <p>
                {info}
            </p>
        </div>
    </div>
);

ResumeItem.propTypes = {
    header: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    year: PropTypes.string.isRequired,
    info: PropTypes.string.isRequired,
};

export default ResumeItem;
