/* global document, window */
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, } from 'react-router-dom';
import { createStore, combineReducers, } from 'redux';
import { Provider, } from 'react-redux';
import App from './App';
import * as serviceWorker from './serviceWorker';
import statuses from './statuses';
import 'normalize.css';
import './reset.scss';


function styleguide(state = {
    text: '', textDisabled: false, textSuccess: false, textError: false,
}, action) {
    switch (action.type) {


        case 'STYLEGUIDE_UPDATE_TEXT_VALUE': {
            return { ...state, textValue: action.textValue, };
        }

        case 'STYLEGUIDE_UPDATE_TEXT_STATUS': {
            return { ...state, textStatus: action.textStatus, };
        }

        case 'STYLEGUIDE_UPDATE_TEXT_MESSAGE': {
            return { ...state, textMessage: action.textMessage, };
        }


        case 'STYLEGUIDE_UPDATE_ORDINAL_SLIDER_VALUE': {
            return { ...state, ordinalSliderValue: action.ordinalSliderValue, };
        }

        case 'STYLEGUIDE_UPDATE_ORDINAL_SLIDER_STATUS': {
            return { ...state, ordinalSliderStatus: action.ordinalSliderStatus, };
        }

        case 'STYLEGUIDE_UPDATE_ORDINAL_SLIDER_MESSAGE': {
            return { ...state, ordinalSliderMessage: action.ordinalSliderMessage, };
        }


        case 'STYLEGUIDE_UPDATE_CHECKBOXES_VALUES': {
            return { ...state, checkboxesValues: action.checkboxesValues, };
        }

        case 'STYLEGUIDE_UPDATE_CHECKBOXES_STATUS': {
            return { ...state, checkboxesStatus: action.checkboxesStatus, };
        }

        case 'STYLEGUIDE_UPDATE_CHECKBOXES_MESSAGE': {
            return { ...state, checkboxesMessage: action.checkboxesMessage, };
        }


        default: {
            return state;
        }

    }
}

function navigation(state = {
    mobileMenuOpen: false,
}, action) {
    switch (action.type) {

        case 'NAVIGATION_UPDATE_MENU_MOBILE': {
            return { ...state, mobileMenuOpen: action.mobileMenuOpen, };
        }

        default: {
            return state;
        }
    }
}

function getApplicationState() {
    return {
        navigation: {
            mobileMenuOpen: false,
        },
        styleguide: {
            textValue: null,
            textStatus: statuses.DEFAULT,
            textMessage: '',
            ordinalSliderValue: null,
            ordinalSliderStatus: statuses.DEFAULT,
            ordinalSliderMessage: '',
            checkboxesValues: [
                {
                    label: 'Four score and seven years ago, our fathers brought forth...',
                    value: 'first',
                    checked: false,
                },
                {
                    label: 'On this continent, a new nation...',
                    value: 'second',
                    checked: false,
                },
                {
                    label: 'Concived in Liberty and the proposition...',
                    value: 'third',
                    checked: true,
                },
                {
                    label: 'That all men are created equal.',
                    value: 'fourth',
                    checked: false,
                },
            ],
            checkboxesStatus: statuses.DEFAULT,
            checkboxesMessage: '',
        },
    };
}

const combinedReducers = combineReducers({ styleguide, navigation, });
/* eslint-disable no-underscore-dangle */
const reduxStore = createStore(combinedReducers, getApplicationState(), window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
/* eslint-enable */

ReactDOM.render(
    <Provider store={reduxStore}>
        <Router>
            <App />
        </Router>
    </Provider>,
    document.getElementById('root'),
    () => {
        setTimeout(() => {
            document.body.classList.add('initialized');
        }, 30);
        // if (window.garhammar.skipIntro) {
        //     const node = document.getElementById('init-anim');
        //     node.parentNode.removeChild(node);
        // }
    },
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
