/* global requestAnimationFrame */
import React, { PureComponent, } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import TextStatuses from './TextStatuses';
import EditSign from './EditSign';
import ClearSign from './ClearSign';
import DisabledSign from './DisabledSign';
import ErrorSign from './ErrorSign';
import SuccessSign from './SuccessSign';
import PendingSign from './PendingSign';
import statuses from '../../statuses';

import './Text.scss';

/* eslint-disable */
function inOutQuad(n) {
    n *= 2;
    if (n < 1) return 0.5 * n * n;
    return - 0.5 * (--n * (n - 2) - 1);
};
/* eslint-enable */

class Text extends PureComponent {

    constructor(props) {
        super(props);
        const hasValue = !!props.value;
        const item = {
            status: props.status,
            message: props.message,
        };
        if (props.status === statuses.DISABLED) {
            item.component = <DisabledSign key="disabled-sign" />;
        } else if (props.status === statuses.PENDING) {
            item.component = <PendingSign key="pending-sign" />;
        } else if (props.status === statuses.ERROR) {
            item.component = <ErrorSign key="error-sign" />;
        } else if (props.status === statuses.SUCCESS) {
            item.component = <ErrorSign key="success-sign" />;
        } else if (hasValue) {
            item.component = <ClearSign key="clear-sign" clear={this.clear} />;
        } else {
            item.component = <EditSign key="edit-sign" setFocus={this.setFocus} />;
        }

        this.state = {
            focus: false,
            isAnimating: false,
            flipped: 0,
            queue: [item,],
        };

    }

    getStateSnapshot({ value, status, message, }) {

        const style = { transform: 'rotateY(180deg)', };

        if (status === statuses.DISABLED) {
            return ({
                component: <DisabledSign key="disabled-sign" style={style} />,
                status,
                message,
            });
        }

        if (status === statuses.PENDING) {
            return ({
                component: <PendingSign key="pending-sign" style={style} />,
                status,
                message,
            });
        }

        if (status === statuses.ERROR) {
            return ({
                component: <ErrorSign key="error-sign" style={style} />,
                status,
                message,
            });
        }

        if (status === statuses.SUCCESS) {
            return ({
                component: <SuccessSign key="success-sign" style={style} />,
                status,
                message,
            });

        }

        if (!value || value === '') {
            return ({
                component: <EditSign key="edit-sign" style={style} setFocus={this.setFocus} tabIndex={-1} />,
                status: statuses.DEFAULT,
                message,
            });
        }

        return ({
            component: <ClearSign key="clear-sign" style={style} clear={this.clear} tabIndex={-1} />,
            status: statuses.DEFAULT,
            message,
        });

    }

    componentDidUpdate() {

        if (this.isAnimating) { return; }
        this.isAnimating = true;
        const item = this.getStateSnapshot(this.props);
        if (this.state.queue[0].component.type === item.component.type) { this.isAnimating = false; return; }
        const queue = [...this.state.queue,];
        queue.push(item);
        this.isAnimating = true;
        const style = { transform: 'rotateY(0deg)', };
        const startAngle = this.state.flipped;
        const endAngle = startAngle + 180;
        const duration = 400;
        let start = null;
        let end = null;
        let stop = false;
        const self = this;

        function draw(now) {
            if (stop) {
                const elem = queue[1];
                self.isAnimating = false;

                self.setState({
                    flipped: 0,
                    isAnimating: false,
                    queue: [{
                        ...elem,
                        component: React.cloneElement(elem.component, { tabIndex: 0, style, }),
                    },],
                });
                return;
            }

            if (now >= end) { stop = true; }
            const p = (now - start) / duration;
            const rotate = inOutQuad(p);
            const x = startAngle + (endAngle - startAngle) * rotate;
            self.setState({ flipped: x, isAnimating: true, });
            requestAnimationFrame(draw);
        }

        function startAnim(timeStamp) {
            start = timeStamp;
            end = start + duration;
            draw(timeStamp);
        }

        this.setState({
            queue,
        }, () => {
            requestAnimationFrame(startAnim);
        });


    }

    onChange = (event) => {
        const { value, } = event.target;
        const { onChange, disabled, id, } = this.props;

        if (disabled) { return; }

        onChange(event, {
            type: 'change',
            value,
            id,
        });
    };

    setFocus = () => {
        this.setState({ focus: true, });
        this.input.focus();
    }

    setBlur = () => {
        this.setState({ focus: false, });
    }

    clear = (event) => {

        const { onChange, id, } = this.props;

        this.setState({
            focus: true,
        }, () => {
            onChange(event, {
                type: 'clear',
                value: '',
                id,
            });
        });

        this.input.focus();
    }

    setInputRef = (element) => { this.input = element; };

    setFlipperRef = (element) => { this.flipper = element; };

    render() {

        const {
            value,
            id,
            statusPlaceholder,
            hint,
            status,
            placeholder,
        } = this.props;
        const { focus, flipped, isAnimating, queue, } = this.state;
        const hasValue = !!value;

        const flippedStyle = { transform: `rotateY(${flipped}deg)` };
        const textProps = {};
        if (hint) {
            textProps.autoComplete = 'off';
        }

        let currentStatus;

        if (flipped < 90) {
            currentStatus = queue[0].status;
        } else {
            currentStatus = queue[1].status;
        }

        const classes = cx('interaction-text', {
            'has-value': hasValue,
            'is-disabled': currentStatus === statuses.DISABLED,
            'is-loading': currentStatus === statuses.PENDING,
            'has-focus': focus,
            'has-error': currentStatus === statuses.ERROR,
            'has-success': currentStatus === statuses.SUCCESS,
            'is-animating': isAnimating,
        });

        return (
            <div className={classes}>
                <div className="hint">{hint}</div>
                <div className="placeholder">{placeholder}</div>
                <label htmlFor={id}>
                    <input
                        name={id}
                        type="text"
                        onChange={this.onChange}
                        value={value || ''}
                        ref={this.setInputRef}
                        onFocus={this.setFocus}
                        onBlur={this.setBlur}
                        disabled={status === statuses.DISABLED}
                        {...textProps}
                    />
                    <div className="sign-wrapper">
                        <div
                            className="text-flipper"
                            ref={this.setFlipperRef}
                            style={flippedStyle}
                        >
                            {
                                queue.map(item => (item.component))
                            }
                        </div>
                    </div>
                    <TextStatuses
                        queue={queue}
                        statusPlaceholder={statusPlaceholder}
                    />
                </label>

            </div>
        );
    }

}

Text.propTypes = {
    onChange: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    value: PropTypes.string,
    statusPlaceholder: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    status: PropTypes.string,
    message: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.bool,
    ]),
    hint: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    placeholder: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
};

Text.defaultProps = {
    statusPlaceholder: (<span>&nbsp;</span>),
    value: null,
    hint: '',
    placeholder: '',
    status: statuses.DEFAULT,
    message: '',
};

export default Text;
