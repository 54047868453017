import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Icon from './Icon';
import './Sign.scss';


const Sign = ({ glyph, className, }) => {

    if (glyph === 'PENDING') {
        return (
            <div className={cx( 'sign', className, )}>
                <span className="pending-dot-first"/>
                <span className="pending-dot-middle"/>
                <span className="pending-dot-last"/>
            </div>
        );
    }

    return (
        <div className={cx( 'sign', className, )}>
            <Icon glyph={glyph}/>
        </div>
    );
};

Sign.propTypes = {
    glyph: PropTypes.string.isRequired,
    className: PropTypes.string,
};

Sign.defaultProps = {
    className: '',
};

export default Sign;
