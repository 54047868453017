/* global requestAnimationFrame, */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import statuses from '../../statuses';
import OpacitySwitch from '../OpacitySwitch';
import MinusSign from './MinusSign';
import PlusSign from './PlusSign';
import NullSign from './NullSign';
import './Slider.scss';
import './OrdinalSlider.scss';

const INCREMENT = 'increment';
const DECREMENT = 'decrement';
const BUTTON = 'button';
const SLIDE = 'slide';
const nullType = (<NullSign />).type;

const ACTIVE_BUTTON = {
    increment: 'increment',
    decrement: 'decrement',
    none: '',
};


/* eslint-disable */
function inOutQuad(n) {
    n *= 2;
    if (n < 1) return 0.5 * n * n;
    return - 0.5 * (--n * (n - 2) - 1);
}
/* eslint-enable */

function getIndexOfValue(value, values) {
    if (value === null) { return 0; }
    return values.map(e => e.value).indexOf(value);
}

class OrdinalSlider extends PureComponent {

    state = {
        focus: false,
        active: false,
    }

    constructor(props) {
        super(props);

        const { value, values, status, } = this.props;

        const currentIndex = getIndexOfValue(value, values);
        const { increment, decrement, } = this.getSpinElements({
            nextIndex: currentIndex,
            currentIndex,
            value,
            values,
            status,
        });

        this.state = {
            focus: false,
            active: false,
            isAnimating: false,
            flipped: 0,
            messages: [
                {
                    status: props.status,
                    message: props.message,
                }
            ],
            activeButton: ACTIVE_BUTTON.none,
            increment,
            decrement,
        };
    }

    componentDidUpdate() {

        if (this.hasOngoingAnimation()) { return; }
        this.isAnimating = INCREMENT;

        const { value, values, } = this.props;
        const currentIndex = getIndexOfValue(value, values);

        let fakePrevIndex = null;
        const decrementType = this.state.decrement[0].type;
        const incrementType = this.state.increment[0].type;
        const lengthOfValues = (values.length - 1);

        if (currentIndex === 0 && decrementType !== nullType) {
            fakePrevIndex = 1;
        } else if (currentIndex === lengthOfValues && incrementType !== nullType) {
            fakePrevIndex = lengthOfValues - 1;
        } else if (currentIndex > 0 && decrementType === nullType) {
            fakePrevIndex = currentIndex - 1;
        } else if (currentIndex < lengthOfValues && incrementType === nullType) {
            fakePrevIndex = currentIndex + 1;
        } else {
            this.isAnimating = null;
            return;
        }

        this.spin({
            type: SLIDE,
            nextIndex: currentIndex,
            currentIndex: fakePrevIndex,
            activeButton: ACTIVE_BUTTON.none,
            ...this.props,
        });

    }

    hasOngoingAnimation = () => this.isAnimating === INCREMENT || this.isAnimating === DECREMENT;

    spin = (data) => {
        const queue = this.getSpinElements(data);
        const startAngle = 0;
        const endAngle = startAngle + 180;
        const duration = 400;
        let start = null;
        let end = null;
        let stop = false;
        const style = { transform: 'rotateY(0deg)' };
        const self = this;

        function draw(now) {
            if (stop) {

                self.isAnimating = false;
                let incrementElem;
                let decrementElem;
                const state = {
                    ...self.state,
                    isAnimating: false,
                    flipped: 0,
                    activeButton: ACTIVE_BUTTON.none,
                };

                if (self.state.increment.length > 1) {
                    incrementElem = React.cloneElement(self.state.increment[1], { tabIndex: 0, style, });
                    state.increment = [incrementElem,];
                }

                if (self.state.decrement.length > 1) {
                    decrementElem = React.cloneElement(self.state.decrement[1], { tabIndex: 0, style, });
                    state.decrement = [decrementElem,];
                }

                self.setState(state);
                return;

            }
            if (now >= end) { stop = true; }
            const p = (now - start) / duration;
            const rotate = inOutQuad(p);
            const x = startAngle + (endAngle - startAngle) * rotate;
            self.setState({ flipped: x, isAnimating: true, });
            requestAnimationFrame(draw);
        }

        function startAnim(timeStamp) {
            start = timeStamp;
            end = start + duration;
            draw(timeStamp);
        }

        this.setState({
            increment: queue.increment,
            decrement: queue.decrement,
            activeButton: data.activeButton,
        }, () => {
            requestAnimationFrame(startAnim);
        });

    };

    getSpinElements = ({ type, currentIndex, nextIndex, values, }) => {

        const style = { transform: 'rotateY(180deg)' };
        let increment;
        let decrement;

        // increment
        if (nextIndex > currentIndex) {

            if (nextIndex === (values.length - 1)) {

                increment = [
                    <PlusSign key="increment-sign-prev" tabIndex={-1} />,
                    <NullSign key="null-sign" style={style} />,
                ];

            } else if (type === SLIDE) {

                increment = [
                    <PlusSign key="increment-sign" onClick={this.onIncrement} />,
                ];

            } else if (type === BUTTON) {

                increment = [
                    <PlusSign key="increment-sign-prev" tabIndex={-1} />,
                    <PlusSign key="increment-sign" style={style} onClick={this.onIncrement} />,
                ];

            }

            if (nextIndex === 0 && currentIndex === -1) {

                decrement = [
                    <NullSign key="null-sign" style={style} />,
                ];

            } else if (currentIndex === 0) {

                decrement = [
                    <NullSign key="null-sign" />,
                    <MinusSign key="decrement-sign" style={style} onClick={this.onDecrement} />,
                ];

            } else {

                decrement = [
                    <MinusSign key="decrement-sign" onClick={this.onDecrement} />,
                ];

            }

            return {
                increment,
                decrement,
            };

        }

        if (nextIndex < currentIndex) {

            if (nextIndex === 0) {

                decrement = [
                    <MinusSign key="decrement-sign" tabIndex={-1} />,
                    <NullSign key="null-sign" style={style} />,
                ];

            } else if (type === SLIDE) {
                decrement = [
                    <MinusSign key="decrement-sign" onClick={this.onDecrement} />,
                ];
            } else if (type === BUTTON) {
                decrement = [
                    <MinusSign key="decrement-sign-prev" tabIndex={-1} />,
                    <MinusSign key="decrement-sign" style={style} onClick={this.onDecrement} />,
                ];
            }

            if (currentIndex === (values.length - 1)) {

                increment = [
                    <NullSign key="null-sign" />,
                    <PlusSign key="increment-sign" style={style} onClick={this.onIncrement} />,
                ];

            } else {
                increment = [
                    <PlusSign key="increment-sign" onClick={this.onIncrement} />,
                ];
            }

            return {
                increment,
                decrement,
            };

        }

        if (currentIndex === 0) {

            increment = [<PlusSign key="increment-sign" onClick={this.onIncrement} />,];
            decrement = [<NullSign key="null-sign" />,];

        } else if (currentIndex === (values.length - 1)) {

            increment = [<NullSign key="null-sign" />,];
            decrement = [<MinusSign key="decrement-sign" onClick={this.onDecrement} />,];

        } else {

            increment = [<PlusSign key="increment-sign" onClick={this.onIncrement} />,];
            decrement = [<MinusSign key="decrement-sign" onClick={this.onDecrement} />,];
        }

        return {
            increment,
            decrement,
        };


    }

    onIncrement = (event) => {

        if (this.hasOngoingAnimation()) { return; }
        this.isAnimating = INCREMENT;

        const { value, values, onChange, id, } = this.props;
        const currentIndex = getIndexOfValue(value, values);

        if (currentIndex === (values.length - 1)) { this.isAnimating = false; return; }

        // if this is the first time user clicks with null value
        if (value === null) {
            const item = values[0];

            onChange(event, {
                type: 'change',
                value: item.value,
                item,
                id,
            });

            this.spin({
                type: BUTTON,
                nextIndex: 0,
                currentIndex: -1,
                activeButton: ACTIVE_BUTTON.increment,
                ...this.props
            });

            return;
        }


        const index = currentIndex + 1;
        const item = values[index];

        onChange(event, {
            type: 'change',
            value: item.value,
            item,
            id,
        });

        this.spin({
            type: BUTTON,
            nextIndex: index,
            currentIndex,
            activeButton: ACTIVE_BUTTON.increment,
            ...this.props
        });

    }

    onDecrement = (event) => {

        if (this.hasOngoingAnimation()) { return; }
        this.isAnimating = DECREMENT;

        const { value, values, onChange, id, } = this.props;
        const currentIndex = getIndexOfValue(value, values);

        if (currentIndex === 0) { this.isAnimating = false; return; }

        const index = currentIndex - 1;
        const item = values[index];

        onChange(event, {
            type: 'change',
            value: item.value,
            item,
            id,
        });

        this.spin({
            type: BUTTON,
            nextIndex: index,
            currentIndex,
            activeButton: ACTIVE_BUTTON.decrement,
            ...this.props
        });
    }

    componentDidMount() {
        this.setState({
            width: this.wrapper.getBoundingClientRect().width,
        });
    }

    onChange = (event) => {
        const { value, } = event.target;
        const { onChange, status, values, id, } = this.props;
        if (status === statuses.DISABLED) { return; }
        const item = values[parseInt(value, 10)];
        onChange(event, {
            type: 'change',
            value: item.value,
            item,
            id,
        });
    }

    onClick = (event) => {
        this.onChange(event);
    }

    setInputRef = (element) => { this.input = element; };

    setWrapperRef = (element) => { this.wrapper = element; };

    setFocus = () => {
        this.setState({ focus: true, });
        this.input.focus();
    }

    setBlur = () => {
        this.timer = setTimeout(() => {
            this.setState({ focus: false, });
        }, 100);
    }

    onMouseDown = () => {
        this.setState({ active: true, });
    }

    onMouseUp = () => {
        this.setState({ active: false, });
    }

    render() {

        const {
            id,
            value,
            values,
            status,
            placeholder,
            statusPlaceholder,
            valuePlaceholder,
            hint,
        } = this.props;
        const { focus, active, increment, decrement, flipped, activeButton, } = this.state;
        const hasHint = !!hint;
        const modifiedValue = getIndexOfValue(value, values);
        const length = values.length - 1;
        const xOffset = `${100 * modifiedValue / length}%`;
        const xStyle = {
            left: xOffset,
        };
        const xWidth = {
            width: xOffset,
        };

        let incrementFlippedStyle = null;
        let decrementFlippedStyle = null;

        if (increment.length === 2) {
            incrementFlippedStyle = { transform: `rotateY(${flipped}deg)` };
        }

        if (decrement.length === 2) {
            decrementFlippedStyle = { transform: `rotateY(${flipped}deg)` };
        }

        const classes = cx('interaction-slider', 'ordinal-slider', `items-${values.length}`, {
            'has-value': !!value,
            'is-active': active,
            'is-disabled': status === statuses.DISABLED,
            'is-loading': status === statuses.PENDING,
            'has-focus': focus,
            'has-error': status === statuses.ERROR,
            'has-success': status === statuses.SUCCESS,
            'has-hint': hasHint,
        });

        const decrementButtonClasses = flipped < 90 && activeButton === ACTIVE_BUTTON.decrement ? 'decrement-wrapper active' : 'decrement-wrapper';
        const incrementButtonClasses = flipped < 90 && activeButton === ACTIVE_BUTTON.increment ? 'increment-wrapper active' : 'increment-wrapper';

        return (
            <div className={classes} ref={this.setWrapperRef}>

                <div className="placeholder">{placeholder}</div>

                {hint ? <div className="hint">{hint}</div> : null}

                <div className="value">

                    <div className="value-placeholder">
                        {valuePlaceholder}
                    </div>

                    <OpacitySwitch>
                        {value ? `- ${values[modifiedValue].label}` : <span>&nbsp;</span>}
                    </OpacitySwitch>
                </div>

                <div className="slider-wrapper">

                    <div className={decrementButtonClasses} style={decrementFlippedStyle}>
                        {decrement.map(component => component)}
                    </div>

                    <div className="slider-track">
                        {
                            values.map((item, i) => {
                                let cls = `indicator indicator-${i}`;
                                if (i < modifiedValue) {
                                    cls = `active ${cls}`;
                                }

                                return (
                                    <span
                                        key={`indicator-${item.value}`}
                                        className={cls}
                                    />
                                );
                            })
                        }
                        <div className="slider-track-handle" style={xStyle} />
                        <div className="slider-track-focus" style={xStyle} />
                        <div className="slider-track-overlay" style={xWidth} />
                    </div>

                    <label htmlFor={id}>
                        <input
                            name={id}
                            type="range"
                            ref={this.setInputRef}
                            onChange={this.onChange}
                            min={0}
                            max={length}
                            step={1}
                            value={modifiedValue}
                            onFocus={this.setFocus}
                            onBlur={this.setBlur}
                            onMouseDown={this.onMouseDown}
                            onMouseUp={this.onMouseUp}
                            onClick={this.onClick}
                        />
                    </label>

                    <div className="input-statuses">

                        <div className="labels-placeholder">{statusPlaceholder}</div>

                        <span className="label label-first">
                            {values[0].label}
                        </span>
                        <span className="label label-last">
                            {values[length].label}
                        </span>
                    </div>

                    <div className={incrementButtonClasses} style={incrementFlippedStyle}>
                        {increment.map(component => component)}
                    </div>
                </div>
            </div>
        );
    }
}

OrdinalSlider.propTypes = {
    onChange: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    status: PropTypes.string,
    message: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.bool,
    ]),
    value: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    })),
    placeholder: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    valuePlaceholder: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    statusPlaceholder: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    hint: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
};

OrdinalSlider.defaultProps = {
    value: null,
    status: statuses.DEFAULT,
    message: '',
    valuePlaceholder: (<span>&nbsp;</span>),
    statusPlaceholder: (<span>&nbsp;</span>),
    hint: null,
};

export default OrdinalSlider;
