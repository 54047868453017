import React from 'react';
import { Link, } from 'react-router-dom';
import './Header.scss';

const Header = () => (
    <header id="header">
        <section>

            <div className="reladtive">

                <Link to="/" className="logo-link">
                    <svg viewBox="0 0 42 42" preserveAspectRatio="none">
                        <path d="M1 21 L21 1 L41 21 L21 41 Z" fill="none" stroke="#47525d" />
                        <path d="M11 11 L31 31" fill="none" stroke="#47525d" />
                        <path d="M21 21 L41 21" fill="none" stroke="#47525d" />
                    </svg>
                </Link>

                <ul className="tablet">
                    <li>
                        <Link to="/styleguide">Styleguide</Link>
                    </li>
                    <li>
                        <Link to="/photos">Photos</Link>
                    </li>
                </ul>
            </div>
        </section>
    </header>
);

export default Header;

// Header.propTypes = {
//     toggleMenu: PropTypes.func.isRequired,
//     mobileMenuOpen: PropTypes.bool.isRequired,
// };

// const mapStateToProps = state => ({
//     mobileMenuOpen: state.navigation.mobileMenuOpen,
// });

// const mapDispatchToProps = dispatch => ({
//     toggleMenu: mobileMenuOpen => dispatch({ type: 'NAVIGATION_UPDATE_MENU_MOBILE', mobileMenuOpen, }),
// });


// export default connect(mapStateToProps, mapDispatchToProps)(Header);
