import React from 'react';
import PropTypes from 'prop-types';
import Sign from '../Icons/Sign';

const ClearSign = ({ clear, style, tabIndex, }) => (
    <button
        className="clear-sign"
        onClick={clear}
        tabIndex={tabIndex}
        style={style}
    >
        <Sign glyph="CROSS"/>
    </button>
);

ClearSign.propTypes = {
    clear: PropTypes.func.isRequired,
    style: PropTypes.shape({
        transform: PropTypes.string,
    }),
    tabIndex: PropTypes.number,
};

ClearSign.defaultProps = {
    tabIndex: 0,
    style: {},
};

export default ClearSign;