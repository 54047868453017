import React from 'react';
import PropTypes from 'prop-types';
import Page from '../Page';
import Text from '../../ui/Interactions/Text';
import OrdinalSlider from '../../ui/Interactions/OrdinalSlider';
import Checkboxes from '../../ui/Interactions/Checkboxes';
import Cols from '../../ui/Layouts/Cols';
import statuses from '../../statuses';
import './Styleguide.scss';

const Styleguide = ({
    textValue,
    textStatus,
    textMessage,
    onDisable,
    onChange,
    onSuccess,
    onError,
    onPending,
    onReset,
    ordinalSliderStatus,
    ordinalSliderValue,
    ordinalSliderMessage,
    checkboxesValues,
    checkboxesStatus,
    checkboxesMessage,
}) => (

        <Page title="Styleguide | Garhammar" className="styleguide">

            <section>
                <article>
                    <div className="styleguide">

                        <Cols>
                            <OrdinalSlider
                                id='ordinalSlider'
                                placeholder='Ordinal slider question'
                                values={[
                                    {
                                        label: 'First fockrre a longer label',
                                        value: 'ficker',
                                    },
                                    {
                                        label: 'secodn fucker',
                                        value: 'second',
                                    },
                                    {
                                        label: 'fucker third',
                                        value: 'third',
                                    },
                                    {
                                        label: 'forth evil value has a dog',
                                        value: 'fourth',
                                    },
                                    {
                                        label: 'Focker fifth',
                                        value: 'fifth',
                                    },
                                ]}
                                value={ordinalSliderValue}
                                onChange={onChange}
                                status={ordinalSliderStatus}
                                message={ordinalSliderMessage}
                                valuePlaceholder='First fockrre a longer label'
                                statusPlaceholder='First fockrre a longer label'
                            />
                            <div>
                                <strong>Evil has no boundries</strong>

                                <Checkboxes
                                    values={checkboxesValues}
                                    onChange={onChange}
                                    status={checkboxesStatus}
                                    message={checkboxesMessage}
                                />
                            </div>
                        </Cols>

                        <Cols>
                            <p>
                                Lorem Ipsum är en utfyllnadstext från <strong>tryck- och förlagsindustrin</strong>. Lorem ipsum har varit standard ända sedan 1500-talet, när en okänd boksättare tog att antal bokstäver och blandade dem för att göra ett provexemplar av en bok. Lorem ipsum har inte bara överlevt fem århundraden, utan även övergången till elektronisk typografi utan större förändringar. Det blev allmänt känt på 1960-talet i samband med lanseringen av Letraset-ark med avsnitt av Lorem Ipsum, och senare med mjukvaror som Aldus PageMaker.
                    </p>
                            <Text
                                placeholder='Text question'
                                value={textValue}
                                id="text"
                                hint='ÅÅÅÅMMDDXXXX'
                                onChange={onChange}
                                status={textStatus}
                                message={textMessage}
                            />

                        </Cols>
                        <Cols>
                            <button onClick={onDisable} >toggle disable</button>
                            <button onClick={onError} >toggle error</button>
                            <button onClick={onSuccess} >toggle success</button>
                            <button onClick={onPending} >toggle pending</button>
                            <button onClick={onReset} >toggle reset</button>
                        </Cols>

                    </div>
                </article>
            </section>
        </Page>
    );


Styleguide.propTypes = {
    textValue: PropTypes.string,
    textStatus: PropTypes.string,
    textMessage: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.bool,
    ]),
    ordinalSliderValue: PropTypes.string,
    ordinalSliderStatus: PropTypes.string,
    ordinalSliderMessage: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.bool,
    ]),
    checkboxesValues: PropTypes.arrayOf(PropTypes.shape({})),
    checkboxesStatus: PropTypes.string,
    checkboxesMessage: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.bool,
    ]),
    onChange: PropTypes.func.isRequired,
    onDisable: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    onPending: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
};

Styleguide.defaultProps = {
    textValue: '',
    textStatus: statuses.DEFAULT,
    textMessage: '',
    ordinalSliderValue: '',
    ordinalSliderStatus: statuses.DEFAULT,
    ordinalSliderMessage: '',
};


export default Styleguide;
