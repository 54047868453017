import React from 'react';
import Page from '../Page';
import './Photos.scss';

const Photos = () => (
    <Page title="Photos | Garhammar" className="photos">
        <div className="bg" />
        <div className="drop" />
    </Page>
);

export default Photos;
