import React, { PureComponent, } from 'react';
import PropTypes from 'prop-types';
import { connect, } from 'react-redux';
import Styleguide from './Styleguide';
import statuses from '../../statuses';

class StyleguideContainer extends PureComponent {

    onChange = (ev, input, ) => {
        if (input.id === 'ordinalSlider') {
            const { updateOrdinalSliderValue, } = this.props;
            updateOrdinalSliderValue(input.value);
        } else if (input.id === 'text') {
            const { updateTextValue, } = this.props;
            updateTextValue(input.value);
        } else if (input.id === 'checkboxes') {
            const { updateCheckboxesValues, } = this.props;
            updateCheckboxesValues(input.values);
        }
    }

    onDisable = () => this.update(statuses.DISABLED, (<span>Disabled:: store a <strong>evil</strong> jsx component</span>));

    onSuccess = () => this.update(statuses.SUCCESS, (<span>Success:: store a <strong>evil</strong> jsx component</span>));

    onError = () => this.update(statuses.ERROR, (<span>Error:: store a <strong>evil</strong> jsx component</span>));

    onPending = () => this.update(statuses.PENDING);

    onReset = () => this.update(statuses.DEFAULT);

    update = (status, message = '') => {
        const {
            updateTextStatus,
            updateOrdinalSliderStatus,
            updateTextMessage,
            updateOrdinalSliderMessage,
            updateCheckboxesStatus,
            updateCheckboxesMessage,
        } = this.props;

        updateTextStatus(status);
        updateTextMessage(message);
        updateOrdinalSliderStatus(status);
        updateOrdinalSliderMessage(message);
        updateCheckboxesStatus(status);
        updateCheckboxesMessage(message);
    }

    render() {

        const {
            textValue,
            textStatus,
            textMessage,
            ordinalSliderValue,
            ordinalSliderStatus,
            ordinalSliderMessage,
            checkboxesValues,
            checkboxesStatus,
            checkboxesMessage,
        } = this.props;

        return (
            <Styleguide
                onChange={this.onChange}
                onDisable={this.onDisable}
                onError={this.onError}
                onSuccess={this.onSuccess}
                onPending={this.onPending}
                onReset={this.onReset}
                textValue={textValue}
                textStatus={textStatus}
                textMessage={textMessage}
                ordinalSliderValue={ordinalSliderValue}
                ordinalSliderStatus={ordinalSliderStatus}
                ordinalSliderMessage={ordinalSliderMessage}
                checkboxesValues={checkboxesValues}
                checkboxesStatus={checkboxesStatus}
                checkboxesMessage={checkboxesMessage}
            />
        );
    }
}


StyleguideContainer.propTypes = {
    textValue: PropTypes.string,
    textStatus: PropTypes.string,
    textMessage: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.bool,
    ]),
    updateTextValue: PropTypes.func.isRequired,
    updateTextStatus: PropTypes.func.isRequired,
    updateTextMessage: PropTypes.func.isRequired,
    ordinalSliderValue: PropTypes.string,
    ordinalSliderStatus: PropTypes.string,
    ordinalSliderMessage: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.bool,
    ]),
    updateOrdinalSliderValue: PropTypes.func.isRequired,
    updateOrdinalSliderStatus: PropTypes.func.isRequired,
    updateOrdinalSliderMessage: PropTypes.func.isRequired,
    checkboxesValues: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool,
        ]),
        checked: PropTypes.bool,
        status: PropTypes.string,
    })).isRequired,
    checkboxesStatus: PropTypes.string,
    checkboxesMessage: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.bool,
    ]),
    updateCheckboxesValues: PropTypes.func.isRequired,
    updateCheckboxesStatus: PropTypes.func.isRequired,
    updateCheckboxesMessage: PropTypes.func.isRequired,
};

StyleguideContainer.defaultProps = {
    textValue: null,
    textStatus: statuses.PENDING,
    textMessage: '',
    ordinalSliderValue: null,
    ordinalSliderStatus: statuses.PENDING,
    ordinalSliderMessage: '',
};


const mapStateToProps = state => ({
    textValue: state.styleguide.textValue,
    textStatus: state.styleguide.textStatus,
    textMessage: state.styleguide.textMessage,
    ordinalSliderValue: state.styleguide.ordinalSliderValue,
    ordinalSliderStatus: state.styleguide.ordinalSliderStatus,
    ordinalSliderMessage: state.styleguide.ordinalSliderMessage,
    checkboxesValues: state.styleguide.checkboxesValues,
    checkboxesStatus: state.styleguide.checkboxesStatus,
    checkboxesMessage: state.styleguide.checkboxesMessage,
});

const mapDispatchToProps = dispatch => ({
    updateTextValue: textValue => dispatch({ type: 'STYLEGUIDE_UPDATE_TEXT_VALUE', textValue, }),
    updateTextStatus: textStatus => dispatch({ type: 'STYLEGUIDE_UPDATE_TEXT_STATUS', textStatus, }),
    updateTextMessage: textMessage => dispatch({ type: 'STYLEGUIDE_UPDATE_TEXT_MESSAGE', textMessage, }),
    updateOrdinalSliderValue: ordinalSliderValue => dispatch({ type: 'STYLEGUIDE_UPDATE_ORDINAL_SLIDER_VALUE', ordinalSliderValue, }),
    updateOrdinalSliderStatus: ordinalSliderStatus => dispatch({ type: 'STYLEGUIDE_UPDATE_ORDINAL_SLIDER_STATUS', ordinalSliderStatus, }),
    updateOrdinalSliderMessage: ordinalSliderMessage => dispatch({ type: 'STYLEGUIDE_UPDATE_ORDINAL_SLIDER_MESSAGE', ordinalSliderMessage, }),
    updateCheckboxesValues: checkboxesValues => dispatch({ type: 'STYLEGUIDE_UPDATE_CHECKBOXES_VALUES', checkboxesValues, }),
    updateCheckboxesStatus: checkboxesStatus => dispatch({ type: 'STYLEGUIDE_UPDATE_CHECKBOXES_STATUS', checkboxesStatus, }),
    updateCheckboxesMessage: checkboxesMessage => dispatch({ type: 'STYLEGUIDE_UPDATE_CHECKBOXES_MESSAGE', checkboxesMessage, }),
});

export default connect(mapStateToProps, mapDispatchToProps)(StyleguideContainer);
