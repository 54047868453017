import React from 'react';
import PropTypes from 'prop-types';
import Sign from '../Icons/Sign';

const SuccessSign = ({ style }) => (
    <div className="success-sign" style={style}>
        <Sign glyph="CHECK"/>
    </div>
);

SuccessSign.propTypes = {
    style: PropTypes.shape({
        transform: PropTypes.string,
    }),
};

SuccessSign.defaultProps = {
    style: {},
};

export default SuccessSign;
