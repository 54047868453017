import React from 'react';
import ResumeItem from './ResumeItem';

import './Resume.scss';

const Resume = () => (
    <section className="resume">
        <article>
            <div className="resume-skills">

                <div className="headline"><h3>Skills</h3></div>

                <dl>
                    <dt>Philosophy</dt>
                    <dd>
                        <ul>
                            <li className="active" />
                            <li className="active" />
                            <li className="active" />
                            <li className="active" />
                            <li />
                            <li />
                            <li />
                            <li />
                            <li />
                            <li />
                        </ul>
                    </dd>

                    <dt>Beer brewing</dt>
                    <dd>
                        <ul>
                            <li className="active" />
                            <li />
                            <li />
                            <li />
                            <li />
                            <li />
                            <li />
                            <li />
                            <li />
                            <li />
                        </ul>
                    </dd>

                    <dt>Cosmology</dt>
                    <dd>
                        <ul>
                            <li className="active" />
                            <li className="active" />
                            <li className="active" />
                            <li className="active" />
                            <li className="active" />
                            <li />
                            <li />
                            <li />
                            <li />
                            <li />
                        </ul>
                    </dd>

                    <dt>Javascript</dt>
                    <dd>
                        <ul>
                            <li className="active" />
                            <li className="active" />
                            <li className="active" />
                            <li className="active" />
                            <li className="active" />
                            <li className="active" />
                            <li className="active" />
                            <li className="active" />
                            <li />
                            <li />
                        </ul>
                    </dd>

                    <dt>Beer drinking</dt>
                    <dd>
                        <ul>
                            <li className="active" />
                            <li className="active" />
                            <li className="active" />
                            <li className="active" />
                            <li className="active" />
                            <li className="active" />
                            <li className="active" />
                            <li className="active" />
                            <li className="active" />
                            <li className="active" />
                        </ul>
                    </dd>

                    <dt>Roman Emperors</dt>
                    <dd>
                        <ul>
                            <li className="active" />
                            <li className="active" />
                            <li className="active" />
                            <li className="active" />
                            <li className="active" />
                            <li className="active" />
                            <li className="active" />
                            <li />
                            <li />
                            <li />
                        </ul>
                    </dd>

                    <dt>Love</dt>
                    <dd className="love">
                        <span>
                            - Flatline -
                        </span>
                    </dd>
                </dl>


                <div className="headline"><h3>Education</h3></div>
                <strong>Master of Science, Electrical Engineering</strong>
                <p>
                    Faculty of Engineering<br />
                    1999 - 2004, Lunds University, Sweden
                </p>

            </div>

            <div className="resume-exp">

                <div className="headline"><h3>Experience</h3></div>

                <ul>
                    <li>
                        <ResumeItem
                            year='2016'
                            header='Lysa'
                            link='https://lysa.se'
                            info='Developing the worlds greatest roboadvisor. React, Webpack, Sass, D3, etc...'
                        />
                    </li>
                    <li>
                        <ResumeItem
                            year='2015'
                            header='Viaplay'
                            link='https://viaplay.se'
                            info='Frontent engineer for Viaplays streaming services'
                        />
                    </li>
                    <li>
                        <ResumeItem
                            year='2014'
                            header='Dagens nyheter'
                            link='https://dn.se'
                            info='Implemented interactive components for Swedens largest newspaper'
                        />
                    </li>
                    <li>
                        <ResumeItem
                            year='2010'
                            header='Avanza'
                            link='https://avanza.se'
                            info='Frontend engineer focusing on signup and customer interactions for a net broker'
                        />
                    </li>
                    <li>
                        <ResumeItem
                            year='2005'
                            header='Tactel'
                            link='https://tactel.se'
                            info='Everything from test engineer to backend developer to overseeing internships'
                        />
                    </li>
                </ul>

            </div>

        </article>
    </section>
);

export default Resume;
