import React from 'react';
import PropTypes from 'prop-types';
import Sign from '../Icons/Sign';

const NullSign = ({ style }) => (
    <div className="null-sign" style={style}>
        <Sign glyph="DISABLED"/>
    </div>
);

NullSign.propTypes = {
    style: PropTypes.shape({
        transform: PropTypes.string,
    }),
};

NullSign.defaultProps = {
    style: {},
};

export default NullSign;