import React from 'react';
import PropTypes from 'prop-types';
import Sign from '../Icons/Sign';

const DisabledSign = ({ style }) => (
    <div className="disabled-sign" style={style}>
        <Sign glyph="DISABLED"/>
    </div>
);

DisabledSign.propTypes = {
    style: PropTypes.shape({
        transform: PropTypes.string,
    }),
};

DisabledSign.defaultProps = {
    style: {},
};

export default DisabledSign;