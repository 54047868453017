/* global requestAnimationFrame */
import React, { PureComponent, } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import CheckboxCheckedSign from './CheckboxCheckedSign';
import CheckboxUncheckedSign from './CheckboxUncheckedSign';
import DisabledSign from './DisabledSign';
import ErrorSign from './ErrorSign';
import SuccessSign from './SuccessSign';
import PendingSign from './PendingSign';
import statuses from '../../statuses';
import './Checkbox.scss';


/* eslint-disable */
function inOutQuad(n) {
    n *= 2;
    if (n < 1) return 0.5 * n * n;
    return -0.5 * (--n * (n - 2) - 1);
}
/* eslint-enable */


function getStateSnapshot({ item, status, }) {

    const style = { transform: 'rotateY(180deg)', };

    if (status === statuses.DISABLED) {

        return ({
            component: <DisabledSign key="disabled-sign" style={style} />,
        });

    }

    if (status === statuses.PENDING) {

        return ({
            component: <PendingSign key="pending-sign" style={style} />,
        });

    }

    if (status === statuses.ERROR) {

        return ({
            component: <ErrorSign key="error-sign" style={style} />,
        });

    }

    if (status === statuses.SUCCESS) {

        return ({
            component: <SuccessSign key="success-sign" style={style} />,
        });

    }

    if (item.checked) {

        return ({
            component: <CheckboxCheckedSign key="checkbox-checked-sign" style={style} />,
        });

    }

    return ({
        component: <CheckboxUncheckedSign key="checkbox-unchecked-sign" style={style} />,
    });

}


class Checkbox extends PureComponent {

    constructor(props) {
        super(props);

        const item = {
            status: props.status,
            message: props.message,
        };

        if (props.status === statuses.DISABLED) {
            item.component = <DisabledSign key="disabled-sign" />;
        } else if (props.status === statuses.PENDING) {
            item.component = <PendingSign key="pending-sign" />;
        } else if (props.status === statuses.ERROR) {
            item.component = <ErrorSign key="error-sign" />;
        } else if (props.status === statuses.SUCCESS) {
            item.component = <ErrorSign key="success-sign" />;
        } else if (props.item.checked) {
            item.component = <CheckboxCheckedSign key="checkbox-checked-sign"/>;
        } else {
            item.component = <CheckboxUncheckedSign key="checkbox-unchecked-sign"/>;
        }

        this.state = {
            focus: false,
            flipped: 0,
            isAnimating: false,
            queue: [item,],
        };

    }

    onChange = (event) => {
        const { id, } = event.target;
        const { onChange, disabled, item, } = this.props;

        if (disabled) { return; }

        this.setState({
            focus: true,
        }, () => {
            onChange(event, {
                type: 'change',
                checked: !item.checked,
                id,
            });
        });
    };

    onClick = () => {
        this.setState({
            focus: true,
        });
    }

    componentDidUpdate() {
        if (this.isAnimating) { return; }

        this.isAnimating = true;
        const item = getStateSnapshot(this.props);
        if (this.state.queue[0].component.type === item.component.type) { this.isAnimating = false; return; }

        const queue = [...this.state.queue,];
        queue.push(item);
        this.isAnimating = true;
        const style = { transform: 'rotateY(0deg)', };
        const startAngle = this.state.flipped;
        const endAngle = startAngle + 180;
        const duration = 300;
        let start = null;
        let end = null;
        let stop = false;
        const self = this;

        function draw(now) {
            if (stop) {
                const elem = queue[1];
                self.isAnimating = false;

                self.setState({
                    flipped: 0,
                    isAnimating: false,
                    queue: [{
                        ...elem,
                        component: React.cloneElement(elem.component, { tabIndex: 0, style, }),
                    },],
                });
                return;
            }

            if (now >= end) { stop = true; }

            const p = (now - start) / duration;
            const rotate = inOutQuad(p);
            const x = startAngle + (endAngle - startAngle) * rotate;
            self.setState({ flipped: x, isAnimating: true, });
            requestAnimationFrame(draw);
        }

        function startAnim(timeStamp) {
            start = timeStamp;
            end = start + duration;
            draw(timeStamp);
        }

        this.setState({
            queue,
        }, () => {
            requestAnimationFrame(startAnim);
        });
    }

    setFocus = () => {
        clearTimeout(this.timer);
        this.setState({ focus: true, });
        this.input.focus();
    }

    setBlur = () => {
        this.timer = setTimeout(() => {
            this.setState({ focus: false, });
        }, 100);
    }

    onMouseEnter = this.setFocus;

    onMouseLeave = this.setBlur;

    setInputRef = element => this.input = element;

    render() {
        const { item, status, } = this.props;
        const { focus, flipped, isAnimating, queue, } = this.state;

        const flippedStyle = { transform: `rotateY(${flipped}deg)`, };
        // const textProps = {};
        // let currentStatus;

        // if (flipped < 90) {
        //     currentStatus = queue[0].status;
        // } else {
        //     currentStatus = queue[1].status;
        // }

        const disabled = status === statuses.DISABLED;

        const classes = cx('checkbox', {
            'has-focus': focus,
            'is-animating': isAnimating,
        });

        const props = {};
        props.disabled = disabled;

        return (
            <label
                className={classes}
                key={item.label}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
                htmlFor={item.value}
            >
                <div className="sign-wrapper">
                    <div className="checkbox-flipper"
                        style={flippedStyle}
                    >
                        {
                            queue.map(queueItem => (queueItem.component))
                        }
                    </div>
                </div>
                <input
                    ref={this.setInputRef}
                    type="checkbox"
                    checked={item.checked}
                    onChange={this.onChange}
                    id={item.value}
                    onClick={this.onClick}
                    name={item.value}
                    onFocus={this.setFocus}
                    onBlur={this.setBlur}
                    {...props}
                />
                <span className='checkbox-label'>{item.label}</span>
            </label>
        );
    }
}

Checkbox.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    status: PropTypes.string,
};

export default Checkbox;
