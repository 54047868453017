import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './OpacitySwitch.scss';

class OpacitySwitch extends React.Component {

    constructor(props) {
        super();
        this.state = {
            visible: true,
            node: props.children,
        };
    }

    componentDidMount() {
        this.switch.addEventListener(
            'transitionend',
            this.onAnimEnd,
            false,
        );
    }

    componentDidUpdate(prevProps) {

        if (prevProps.children !== this.props.children) {
            this.setState({ visible: false, });
        }
    }

    onAnimEnd = () => {

        const { children, } = this.props;

        if (this.state.node === children && this.state.visible) {
            return;
        }

        this.setState({
            node: children,
        }, () => {
            this.setState({ visible: true, });
        });
    }

    setSwitchRef = element => (this.switch = element);

    componentWillUmount() {
        this.switch.remvoeEventListener(
            'transitionend',
            this.onAnimEnd,
            false,
        );
    }

    render() {

        return (
            <div
                ref={this.setSwitchRef}
                className={cx('opacity-switch', { visible: this.state.visible, })}
            >
                {this.state.node}
            </div>
        );
    }
}

OpacitySwitch.propTypes = {
    children: PropTypes.node,
};

OpacitySwitch.defaultProps = {
    children: null,
};

export default OpacitySwitch;
