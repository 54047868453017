import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Checkbox from './Checkbox';

import './Checkboxes.scss';

class Checkboxes extends Component {

    onChange = (ev, input) => {
        const { values, onChange, } = this.props;
        const index = values.findIndex(item => item.value === input.id);
        const items = [...values,];
        items[index] = { ...items[index], checked: input.checked, };
        onChange(ev, {
            type: 'change',
            values: items,
            id: 'checkboxes',
        });
    }

    render() {
        const { values, } = this.props;
        const classes = cx('checkboxes');

        return (
            <div className={classes}>
                {
                    values.map(item => (
                        <Checkbox
                            key={item.value}
                            item={item}
                            onChange={this.onChange}
                        />
                    ))
                }
            </div>
        );
    }
}

Checkboxes.propTypes = {
    onChange: PropTypes.func.isRequired,
    values: PropTypes.arrayOf(PropTypes.shape({})),
};

export default Checkboxes;
