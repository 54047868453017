/* globals window, document */

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './Page.scss';

class Page extends React.Component {

    componentDidMount() {
        if (this.props.title) {
            document.title = this.props.title;
        }

        window.scrollTo(0, 0);
    }

    render() {
        const { className, children, } = this.props;
        const classes = cx('page', className);

        return (
            <div className={classes}>
                <div className="page-view">{children}</div>
            </div>
        );
    }
}

Page.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
    className: PropTypes.string,
};

Page.defaultProps = {
    className: '',
    title: '',
};

export default Page;
