import React from 'react';
import PropTypes from 'prop-types';
import Sign from '../Icons/Sign';

const ErrorSign = ({ style, }) => (
    <div className="error-sign" style={style}>
        <Sign glyph="ERROR_OUTLINE"/>
    </div>
);

ErrorSign.propTypes = {
    style: PropTypes.shape({
        transform: PropTypes.string,
    }),
};

ErrorSign.defaultProps = {
    style: {},
};

export default ErrorSign;
