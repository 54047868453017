import React from 'react';
import PropTypes from 'prop-types';
import Sign from '../Icons/Sign';

const PlusSign = ({ onClick, style, tabIndex, }) => (
    <button
        className="plus-sign"
        onClick={onClick}
        tabIndex={tabIndex}
        style={style}
    >
        <Sign glyph="PLUS_CIRCLE"/>
    </button>
);

PlusSign.propTypes = {
    onClick: PropTypes.func,
    tabIndex: PropTypes.number,
    style: PropTypes.shape({
        transform: PropTypes.string,
    }),
};

PlusSign.defaultProps = {
    onClick: () => {},
    tabIndex: 0,
    style: {},
};

export default PlusSign;