import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './Cols.scss';

const Cols = ({ children, fixed, className, }) => {

    const parts = React.Children.map(children, child =>
        <div className='col'>{child}</div>
    );

    return (
        <div className={cx('cols', className, `cols-${children.length}`, { fixed, })}>
            {parts}
        </div>
    );
};

Cols.propTypes = {
    children: PropTypes.node.isRequired,
    fixed: PropTypes.bool,
    className: PropTypes.string,
};

Cols.defaultProps = {
    fixed: false,
    className: '',
};

export default Cols;