import React, { PureComponent, } from 'react';
import cx from 'classnames';
import Utils from '../../Utils';

import './Quotes.scss';

const quotes = [
    {
        text: 'This man, without a doubt, is a paragon of averageness.',
        author: 'Some random dude',
    },
    {
        text: 'I\'m just, wow, the sheer mediocrity of it all. Unheard of.',
        author: 'Another random dude',
    },
    {
        text: 'He has taken ordinariness to the next level.',
        author: 'Mom',
    },
];

function getRandomInt(initialMin, initialMax) {
    const min = Math.ceil(initialMin);
    const max = Math.floor(initialMax);
    return Math.floor(Math.random() * (max - min)) + min;
}

class Quotes extends PureComponent {

    state = {
        index: getRandomInt(0, 2),
        disableAnim: false,
        offset: 0,
        queue: [
            quotes[0],
            quotes[1],
        ],
        opacities: [1, 0,],
    }

    componentDidMount() {
        this.timer = setInterval(() => {
            this.setState({
                index: (this.state.index + 1) % 3,
                offset: 20,
                opacities: [0, 0,],
            });

            this.opacityTimer = setTimeout(() => {
                this.setState({
                    opacities: [0, 1,],
                });
            }, 600);
        }, 10000);

        this.animElem.addEventListener('transitionend', this.onAnimEnd, false);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
        clearTimeout(this.opacityTimer);
        this.animElem.removeEventListener('transitionend', this.onAnimEnd, false);
    }

    onAnimEnd = (ev) => {
        if (ev.propertyName !== 'transform') { return; }

        this.setState({
            disableAnim: true,
            offset: 0,
            queue: [this.state.queue.pop(),],
        }, () => {
            Utils.onNextFrame(() => {
                const { index, } = this.state;
                const queue = [...this.state.queue,];
                queue.push(quotes[(index + 1) % 3]);
                this.setState({
                    disableAnim: false,
                    queue,
                    opacities: [1, 0,],
                });
            });
        });
    }

    setAnimRef = (element) => { this.animElem = element; };

    render() {

        const { disableAnim, queue, offset, opacities, } = this.state;
        const translateStyle = {
            transform: `translateX(-${offset}%)`,
        };
        const classes = cx('anim-child', {
            'disable-anim': disableAnim,
        });

        const items = queue.map((item, i) => (
            <div className="anim-item" key={item.author} style={{ opacity: opacities[i], }}>
                <figure>
                    <blockquote>{item.text}</blockquote>
                    <figcaption><cite>- {item.author}</cite></figcaption>
                </figure>
            </div>
        ));

        return (
            <section className="toned quotes">
                <div className="quote-wrapper">
                    <div className="bg">
                        <span>&#8221;</span>
                    </div>
                    <div className="anim-parent">
                        <div className={classes} style={translateStyle} ref={this.setAnimRef}>
                            {items}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}


export default Quotes;
