import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import AddToHomescreen from './icons/AddToHomescreen';
import ArrowBack from './icons/ArrowBack';
import ArrowDropDown from './icons/ArrowDropDown';
import ArrowForward from './icons/ArrowForward';
import ArrowUp from './icons/ArrowUp';
import Check from './icons/Check';
import CheckboxChecked from './icons/CheckboxChecked';
import CheckboxUnchecked from './icons/CheckboxUnchecked';
import CloudOff from './icons/CloudOff';
import Cross from './icons/Cross';
import Disabled from './icons/Disabled';
import ErrorOutline from './icons/ErrorOutline';
import ExternalLink from './icons/ExternalLink';
import Face from './icons/Face';
import Facebook from './icons/Facebook';
import Instagram from './icons/Instagram';
import LinkedIn from './icons/LinkedIn';
import Menu from './icons/Menu';
import MinusCircle from './icons/MinusCircle';
import Mouse from './icons/Mouse';
import Pencil from './icons/Pencil';
import Person from './icons/Person';
import PlusCircle from './icons/PlusCircle';
import Print from './icons/Print';
import Refresh from './icons/Refresh';
import ThumbsUp from './icons/ThumbsUp';
import Touch from './icons/Touch';
import PlusCircleFilled from './icons/PlusCircleFilled';
import Twitter from './icons/Twitter';
import './Icon.scss';


export const glyphs = {
    ADD_TO_HOMESCREEN: { class: 'icon-add-to-homescreen', icon: AddToHomescreen, },
    ARROW_BACK: { class: 'icon-arrow-back', icon: ArrowBack, },
    ARROW_DROP_DOWN: { class: 'icon-arrow-drop-down', icon: ArrowDropDown, },
    ARROW_FORWARD: { class: 'icon-arrow-forward', icon: ArrowForward, },
    ARROW_UP: { class: 'icon-arrow-up', icon: ArrowUp, },
    CHECK: { class: 'icon-check', icon: Check, },
    CHECKBOX_CHECKED: { class: 'icon-checkbox-checked', icon: CheckboxChecked, },
    CHECKBOX_UNCHECKED: { class: 'icon-checkbox-unchecked', icon: CheckboxUnchecked, },
    CLOUD_OFF: { class: 'icon-cloud-off', icon: CloudOff, },
    CROSS: { class: 'icon-cross', icon: Cross, },
    DISABLED: { class: 'icon-disabled', icon: Disabled, },
    ERROR_OUTLINE: { class: 'icon-error-outline', icon: ErrorOutline, },
    EXTERNAL_LINK: { class: 'icon-external-link', icon: ExternalLink, },
    FACE: { class: 'icon-face', icon: Face, },
    FACEBOOK: { class: 'icons-facebook', icon: Facebook, },
    INSTAGRAM: { class: 'icons-instagram', icon: Instagram, },
    LINKED_IN: { class: 'icon-linked-in', icon: LinkedIn, },
    MENU: { class: 'icon-menu', icon: Menu, },
    MOUSE: { class: 'icon-mouse', icon: Mouse, },
    MINUS_CIRCLE: { class: 'icon-minus-circle', icon: MinusCircle, },
    PENCIL: { class: 'icon-pencil', icon: Pencil, },
    PERSON: { class: 'icon-person', icon: Person, },
    PLUS_CIRCLE: { class: 'icon-plus-circle', icon: PlusCircle, },
    PLUS_CIRCLE_FILLED: {
        class: 'icon-plus-circle-filled',
        icon: PlusCircleFilled,
    },
    PRINT: { class: 'icon-print', icon: Print, },
    REFRESH: { class: 'icon-refresh', icon: Refresh, },
    THUMBS_UP: { class: 'icon-thumbs-up', icon: ThumbsUp, },
    TOUCH: { class: 'icon-touch', icon: Touch, },
    TWITTER: { class: 'icon-twitter', icon: Twitter, },
};

const Icon = ({ glyph, className, }) => {
    const icon = glyphs[glyph];
    const IconElement = icon.icon;
    const classNames = cx('icon-wrapper', className, icon.class);

    return (
        <span className={classNames}>
            <IconElement />
        </span>
    );
};

Icon.propTypes = {
    glyph: PropTypes.string.isRequired,
    className: PropTypes.string,
};

Icon.defaultProps = {
    className: '',
};

export default Icon;
